const DistributerRouter = []

if (window.location.pathname.search("/distributer") != -1){
  DistributerRouter.push(
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/distributer/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Home' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'Transection',
        component: () => import('../views/distributer/Payments/Transection.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Transection' }
          ]
        }
      },
      {
        path: 'wallet-Withdraw-Req',
        name: 'DistributerWalletWithdrawReq',
        component: () => import('../views/distributer/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerWalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'tds-reporting',
        name: 'TdsReportingDistributer',
        component: () => import('../views/distributer/Payments/tdsreport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TdsReportingDistributer' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'ticket',
        component: () => import('../views/distributer/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ticket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'TicketReply',
        component: () => import('../views/distributer/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TicketReply' }
          ]
        }
      },


      {
        path: 'retailers',
        name: 'Retailers',
        component: () => import('../views/distributer/Retailers/Retailers.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Retailers' }
          ]
        }
      },
      {
        path: 'retailers-view/:id',
        name: 'RetailerView',
        component: () => import('../views/distributer/Retailers/RetailerView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerView' }
          ]
        }
      },

      {
        path: 'projects',
        name: 'Projects',
        component: () => import('../views/distributer/Projects/Projects.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Projects' }
          ]
        }
      },


      {
        path: 'distributer-profile',
        name: 'DistributerProfile',
        component: () => import('../views/distributer/Profile/DistributerProfile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerProfile' }
          ]
        }
      },



      {
        path: 'login', name: 'Login',
        component: () => import('../views/distributer/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/distributer/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'resetpassword', name: 'ResetPassword',
        component: () => import('../views/distributer/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/distributer/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      }

    )

  }

  export default DistributerRouter