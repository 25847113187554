<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <router-link class="navbar-brand" to="/staff/home">
            <span class="brand-logo">
              <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 140px" />
            </span>
          </router-link>
        </li>

        <li class="nav-item nav-toggle">
          <a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse"
            ><i
              class="d-block d-xl-none text-primary toggle-icon font-medium-4"
              data-feather="x"
            ></i
            ><i
              class="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
              data-feather="disc"
              data-ticon="disc"
            ></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
      

        


        <li class="nav-item" :class="{ 'active': $route.path === '/staff/home' }">

<router-link class="d-flex align-items-center" to="/staff/home">
  &nbsp; &nbsp;
  <img style="width: 20px" :src="($route.path === '/staff/home') ? '/assets/image/active-icon/dashboard.png' : '/assets/image/dashboard.png'" alt="image" />
  &nbsp; &nbsp;&nbsp;
  <span class="menu-title text-truncate" data-i18n="Home"> Dashboard</span>
</router-link>
</li>




<li class="nav-item has-sub" style="">
          <a
            class="d-flex align-items-center"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target="#master"
            aria-expanded="false"
            aria-controls="master"
          >
          &nbsp; &nbsp;
            <img style="width:20px" src="/assets/image/clients.png" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="User">Users</span></a
          >

          <div class="collapse" id="master" >
            <ul class="menu-content">
           

              
              <li  :class="{ 'active': $route.path === '/staff/admins-staff' }">
          <router-link class="d-flex align-items-center" to="/staff/admins-staff">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/admins-staff') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Admin</span>
          </router-link>
        </li>


            
        <li  :class="{ 'active': $route.path === '/staff/master-distributer-staff' }">
          <router-link class="d-flex align-items-center" to="/staff/master-distributer-staff">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/master-distributer-staff') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Master Distributors</span>
          </router-link>
        </li>

        <li  :class="{ 'active': $route.path === '/staff/distributer-staff' }">
          <router-link class="d-flex align-items-center" to="/staff/distributer-staff">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/distributer-staff') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Distributors</span>
          </router-link>
        </li>

        <li  :class="{ 'active': $route.path === '/staff/retailer-staff' }">
          <router-link class="d-flex align-items-center" to="/staff/retailer-staff">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/retailer-staff') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Retailer</span>
          </router-link>
        </li>



        <li  :class="{ 'active': $route.path === '/staff/professional-staff' }">
          <router-link class="d-flex align-items-center" to="/staff/professional-staff">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/professional-staff') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Profesional</span>
          </router-link>
        </li>
        
     
           
        <li  :class="{ 'active': $route.path === '/staff/staffs' }">
          <router-link class="d-flex align-items-center" to="/staff/staffs">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/staffs') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Staff</span>
          </router-link>
        </li>
                 
        <li  :class="{ 'active': $route.path === '/staff/clients' }">
          <router-link class="d-flex align-items-center" to="/staff/clients">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/staff/clients') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Clients</span>
          </router-link>
        </li>
           
            </ul>
          </div>
        </li>


   
      

        <li class="nav-item" :class="{ 'active': $route.path === '/staff/new-projects' }">
          <router-link class="d-flex align-items-center" to="/staff/new-projects">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/new-projects') ? '/assets/image/active-icon/project.png' : '/assets/image/project.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Projects</span>
          </router-link>
        </li>


    
        <li class="nav-item" :class="{ 'active': $route.path === '/staff/upgrades' }">
          <router-link class="d-flex align-items-center" to="/staff/upgrades">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/upgrades') ? '/assets/image/active-icon/upgrade.png' : '/assets/image/upgrade.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Upgrades</span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ 'active': $route.path === '/staff/challan' }">
          <router-link class="d-flex align-items-center" to="/staff/challan">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/challan') ? '/assets/image/active-icon/challan.png' : '/assets/image/challan.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Challans</span>
          </router-link>
        </li>
   
        <li class="nav-item" :class="{ 'active': $route.path === '/staff/certificate' }">
          <router-link class="d-flex align-items-center" to="/staff/certificate">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/certificate') ? '/assets/image/active-icon/certificate.png' : '/assets/image/certificate.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Certificate</span>
          </router-link>
        </li>
        
       
        <li class="nav-item" :class="{ 'active': $route.path === '/staff/bussiness-doccument-varification' }">
          <router-link class="d-flex align-items-center" to="/staff/bussiness-doccument-varification">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/bussiness-doccument-varification') ? '/assets/image/active-icon/document.png' : '/assets/image/document.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Business Document</span>
          </router-link>
        </li>
        
        <li class="nav-item" :class="{ 'active': $route.path === '/staff/veryfing-professional-projects' }">
          <router-link class="d-flex align-items-center" to="/staff/veryfing-professional-projects">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/veryfing-professional-projects') ? '/assets/image/active-icon/verified.png' : '/assets/image/verified.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Veryfications</span>
          </router-link>
        </li>
        
     

        <li class="nav-item"  :class="{ 'active': $route.path === 'https://www.vakilgiri.com/blog/' }">
          <router-link to="#" @click="pageReaload()"    target="_blank" class="d-flex align-items-center" >
            &nbsp; &nbsp;
            <img style="width: 20px"   :src="($route.path === 'https://www.vakilgiri.com/blog/') ? '/assets/image/active-icon/center.png' : '/assets/image/active-icon/center1.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Learning Center</span>
          </router-link>
        </li>

        <li class="nav-item" :class="{ 'active': $route.path === '/staff/ticket' }">
          <router-link class="d-flex align-items-center" to="/staff/ticket">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/ticket') ? '/assets/image/active-icon/tickets.png' : '/assets/image/tickets.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Ticket</span>
          </router-link>
        </li>


        <li class="nav-item" :class="{ 'active': $route.path === '/staff/staff-leads' }">
          <router-link class="d-flex align-items-center" to="/staff/staff-leads">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/staff/staff-leads') ? '/assets/image/active-icon/leads.png' : '/assets/image/leads.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Leads</span>
          </router-link>
        </li>

     
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
export default {
  name: "StaffSideBar",
  components: {},
  data() {
    return {
      show: "false",
    };
  },
  methods: {
    pageReaload(){
 
 window.open('https://www.vakilgiri.com/blog/', '_blank');
 window.location.reload();
},
    logoIconeChange(value) {
      this.show = value;
    },
  },
};
</script>

  <!-- Integrated styles -->
  <style scoped>
    .main-menu.menu-light .navigation > li ul .active {
      color: #00364f !important;
      background-color: #f21300 !important;
      box-shadow: none !important;
    }

    .main-menu.menu-light .navigation > li.active > a {
      background: #f21300;
      box-shadow: none !important;
      color: #fff;
      font-weight: 400;
      border-radius: 4px;
    }

    .nav-item {
      font-family: "Poppins", sans-serif;
    }

    .nav-item.active {
      background-color: #f21000;
      color: white;
      box-shadow: 0px 0px 0px 0px solid white;
    }

    .nav-item.active a {
      background-color: #f21000;
      color: white;
      box-shadow: none;
    }

    .main-menu.menu-light .navigation li a {
      color: #00364f;
      padding: 10px 15px 10px 15px;
      line-height: 1.45;
    }

    .main-menu:not(.expanded) .navigation li.active a {
      background: #f21000 !important;
      /* box-shadow: none; */
      color: #ffffff;
    }



    .main-menu.menu-light .navigation > li > a {
    margin: 0px;
}



.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-light .navigation > li.sidebar-group-active > a {
    color: #565360;
    background: whitesmoke;
    border-radius: 0px;
}


.main-menu.menu-light .navigation > li ul .active {
    background: #f21000 !important;
    box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
    border-radius: 0px;
    z-index: 1;
}
  </style>