const AdminRouter = []

if (window.location.pathname.search("/admin") != -1){
  AdminRouter.push(
    {
      path: '/',
      name: 'Goto',
      component: () => import('../views/Admin/Dashboard/redirect.vue'),
      meta: {
      //   title: 'home title',
        redirectIfLoggedIn: 'true',
        breadCrumb: [
          { name: 'Goto' }
        ]
      }
    },
    {
      path: 'home',
      name: 'Home',
      component: () => import('../views/Admin/Dashboard/Home.vue'),
      meta: {
      //   title: 'home title',
        redirectIfLoggedIn: 'true',
        breadCrumb: [
          { name: 'Home' }
        ]
      }
    },
   
    // {
    //   path: 'home',
    //   name: 'Home',
    //   component: () => import('../views/Admin/Dashboard/Home.vue'),
    //   meta: {
     
    //     redirectIfLoggedIn: 'true',
    //     breadCrumb: [
    //       { name: 'Home' }
    //     ]
    //   }
    // },
    {
      path: 'admin-profile',
      name: 'AdminProfile',
      component: () => import('../views/Admin/Profile/Profile.vue'),
      meta: {
      //   title: 'home title',
        redirectIfLoggedIn: 'true',
        breadCrumb: [
          { name: 'AdminProfile' }
        ]
      }
    },
    {
      path: 'payment-screen',
      name: 'paymentScreen',
      component: () => import('../views/Admin/Profile/paymentScreen.vue'),
      meta: {
        // title: 'home title',
        redirectIfLoggedIn: 'false',
        breadCrumb: [
          { name: 'paymentScreen' }
        ]
      }
    },
      {
        path: 'retailers',
        name: 'Retailers',
        component: () => import('../views/Admin/Retailers/Retailers.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Retailers' }
          ]
        }
      },
      {
        path: 'retailer-view/:id',
        name: 'RetailerView',
        component: () => import('../views/Admin/Retailers/RetailerView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerView' }
          ]
        }
      },
    
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('../views/Admin/Projects/Projects.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Projects' }
          ]
        }
      },
      {
        path: 'commision-rate/:id',
        name: 'commisionRate',
        component: () => import('../views/Admin/Projects/commisionRate.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'commisionRate' }
          ]
        }
      },
      {
        path: 'packages',
        name: 'Packages',
        component: () => import('../views/Admin/Packages/RetailerPakages.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Packages' }
          ]
        }
      },
      {
        path: 'package-services',
        name: 'PackageServices',
        component: () => import('../views/Admin/Packages/PackageServices.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'PackageServices' }
          ]
        }
      },
      {
        path: 'project-detailes/:id',
        name: 'ProjectDetailes',
        component: () => import('../views/Admin/Projects/ProjectDetailes.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProjectDetailes' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'AdminTicket',
        component: () => import('../views/Admin/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminTicket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'AdminViewTicket',
        component: () => import('../views/Admin/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminViewTicket' }
          ]
        }
      },
      {
        path: 'master-distributer',
        name: 'MasterDistributers',
        component: () => import('../views/Admin/MasterDistributers/MasterDistributers.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributers' }
          ]
        }
      },
      {
        path: 'master-distributer-profile/:id',
        name: 'MasterDistributerView',
        component: () => import('../views/Admin/MasterDistributers/MasterDistributerView.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributerView' }
          ]
        }
      },
      {
        path: 'distributersOf-master-distributer/:id',
        name: 'Distributers',
        component: () => import('../views/Admin/MasterDistributers/Distributers.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Distributers' }
          ]
        }
      },
      {
        path: 'distributer-profile/:id',
        name: 'DistributerProfile',
        component: () => import('../views/Admin/MasterDistributers/DistributerView.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerProfile' }
          ]
        }
      },
      {
        path: 'all-distributers',
        name: 'DestributersAll',
        component: () => import('../views/Admin/MasterDistributers/DestributersAll.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DestributersAll' }
          ]
        }
      },
      {
        path: 'service-charges',
        name: 'ServiceCharges',
        component: () => import('../views/Admin/ServiceCharges/Charges.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ServiceCharges' }
          ]
        }
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('../views/Admin/Clients/Clients.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Clients' }
          ]
        }
      },
      {
        path: 'clients-profile/:id',
        name: 'ClientsProfile',
        component: () => import('../views/Admin/Clients/ClientsProfile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ClientsProfile' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'Transection',
        component: () => import('../views/Admin/Payments/Transection.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Transection' }
          ]
        }
      },
      {
        path: 'tds-report',
        name: 'AdminTds',
        component: () => import('../views/Admin/Payments/tdsreport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminTds' }
          ]
        }
      },

      {
        path: 'wallet-Withdraw-Req',
        name: 'AdminWalletWithdrawReq',
        component: () => import('../views/Admin/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminWalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'login', name: 'Login',
        component: () => import('../views/Admin/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/Admin/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      // {
      //   path: 'resetpassword', name: 'ResetPassword',
      //   component: () => import('../views/Admin/auth/ResetPassword.vue'),
      //   meta: { headerVisible: 'hide' }
      // },
      {
        path: 'resetpassword',
        name: 'AdminResetPassword',
        component: () => import('../views/Admin/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      
      {
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/Admin/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      },
      // {
      //   path: 'termcondition', name: 'TermCondition',
      //   component: () => import('../views/Admin/TermCondition.vue'),
      //   meta: { headerVisible: 'hide' }
      // },
    // {
    //   path: 'retailer',
    //   name: 'retailer',
    //   component: () => import('@/views/leagal-csc/retailer/Dashboard.vue'),
    // },
    // {
    //   path: 'retailer/client',
    //   name: 'retailer-client-user',
    //   component: () => import('@/views/leagal-csc/retailer/user/Client.vue'),
    //   meta: {
    //     pageTitle: 'Retailer',
    //     breadcrumb: [
    //       {
    //         text: 'Client',
    //       },
    //       {
    //         text: 'Client List',
    //         active: true,
    //       },
    //     ],
    //   },
    // }
    )

  }
  
  export default AdminRouter