<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/> -->
  <router-view/>
   <!-- <Retailer /> -->
   <div></div>
</template>

<script>
// import Retailer from '@/views/Retailer/Retailer.vue'
import feather from 'feather-icons'
export default {
  name: 'App',
  components: {
    // Retailer
  },
  mounted () {
    feather.replace()
  }
}
</script>

<style>
html .content.app-content {
    padding: 62px 0px 0px !important;
}
.row {
    --bs-gutter-x: -1rem;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
