<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <a class="navbar-brand" href="/retailer/home">
            <span class="brand-logo">
              <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 140px" />
            </span>
            <!-- <h2 class="brand-text">Vakilgiri</h2> -->
          </a>
        </li>
        <li class="nav-item nav-toggle">
          <a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse"
            ><i
              class="d-block d-xl-none text-primary toggle-icon font-medium-4"
              data-feather="x"
            ></i
            ><i
              class="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
              data-feather="disc"
              data-ticon="disc"
            ></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
      
      
        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/home' }">
          <router-link class="d-flex align-items-center" to="/retailer/home">
            <img style="width: 20px" :src="($route.path === '/retailer/home') ? '/assets/image/active-icon/dashboard.png' : '/assets/image/dashboard.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Dashboard</span>
          </router-link>
        </li>
      
        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/client' }">
          <router-link class="d-flex align-items-center" to="/retailer/client">
            <img style="width: 20px"  :src="($route.path === '/retailer/client') ? '/assets/image/active-icon/clients.png' : '/assets/image/clients.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Clients</span>
          </router-link>
        </li>
     
   

        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/project' }">
          <router-link class="d-flex align-items-center" to="/retailer/project">
            <img style="width: 20px"  :src="($route.path === '/retailer/project') ? '/assets/image/active-icon/project.png' : '/assets/image/project.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Projects</span>
          </router-link>
        </li>


    
        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/all-services' }">
          <router-link class="d-flex align-items-center" to="/retailer/all-services">
            <img style="width: 20px"  :src="($route.path === '/retailer/all-services') ? '/assets/image/active-icon/services.png' : '/assets/image/services.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Services</span>
          </router-link>
        </li>

        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/invoice' }">
          <router-link class="d-flex align-items-center" to="/retailer/invoice">
            <img style="width: 20px"  :src="($route.path === '/retailer/invoice') ? '/assets/image/active-icon/invoice.png' : '/assets/image/active-icon/invoice1.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Invoice</span>
          </router-link>
        </li>



        
    

     

    
     
   
       


        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/packages' }">
          <router-link class="d-flex align-items-center" to="/retailer/packages">
            <img style="width: 20px" :src="($route.path === '/retailer/packages') ? '/assets/image/active-icon/package.png' : '/assets/image/package.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Packages</span>
          </router-link>
        </li>

      

        <li class="nav-item" :class="{ 'active': $route.path === '/retailer/ticket' }">
          <router-link class="d-flex align-items-center" to="/retailer/ticket">
            <img style="width: 20px"  :src="($route.path === '/retailer/ticket') ? '/assets/image/active-icon/tickets.png' : '/assets/image/tickets.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Ticket</span>
          </router-link>
        </li>

        <li v-if="portal != 'true'" class="nav-item" :class="{ 'active': $route.path === '/retailer/tds-reporting' }">
          <router-link class="d-flex align-items-center" to="/retailer/tds-reporting">
            <img style="width: 20px" :src="($route.path === '/retailer/tds-reporting') ? '/assets/image/active-icon/tds.png' : '/assets/image/tds.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">TDS</span>
          </router-link>
        </li>

        <li v-if="portal != 'true'" class="nav-item" :class="{ 'active': $route.path === '/retailer/transections-reporting' }">
          <router-link
            class="d-flex align-items-center"
            to="/retailer/transections-reporting"
          >
            <img style="width: 20px" :src="($route.path === '/retailer/transections-reporting') ? '/assets/image/active-icon/transections-reporting.png' : '/assets/image/transections-reporting.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"
              >Transactions & Reportings</span
            >
          </router-link>
        </li>

     

        <li class="nav-item"  :class="{ 'active': $route.path === 'https://www.vakilgiri.com/blog/' }">
          <router-link to="#" @click="pageReaload()"    target="_blank" class="d-flex align-items-center" >
            <img style="width: 20px"   :src="($route.path === 'https://www.vakilgiri.com/blog/') ? '/assets/image/active-icon/center.png' : '/assets/image/active-icon/center1.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Learning Center</span>
          </router-link>
        </li>

      
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
export default {
  name: "RetailerSidebar",
  data() {
    return {
      portal: "",
      show: "false",
    };
  },
  methods: {
    logoIconeChange(value) {
      this.show = value;
    },
    pageReaload(){
 
      window.open('https://www.vakilgiri.com/blog/', '_blank');
      window.location.reload();
    },
    userData() {
      this.$axios
        .post(
          `retailer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
        
          this.portal = res.data.portal
        });
    },
  },
  created() {
   
    this.userData() ;
  },
};
</script>

<style scoped>
.main-menu.menu-light .navigation > li ul .active {
  color: #00364f !important;
  background-color: #f21300 !important;
  box-shadow: none !important;
}


.main-menu.menu-light .navigation > li.active > a {
    background: #f21300;
    box-shadow: none !important;
    color: #fff;
    font-weight: 400;
    border-radius: 4px;
}

.nav-item {
  font-family: "Poppins", sans-serif;
}


.nav-item.active {
  background-color: #f21000;
  color: white;
  box-shadow: 0px 0px 0px 0px solid white;
}

.nav-item.active a{
  background-color: #f21000;
  color: white;
  box-shadow: none;
}

.main-menu.menu-light .navigation li a {
  color: #00364f;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}



.main-menu:not(.expanded) .navigation li.active a {
    background: #f21000 !important;
    /* box-shadow: none; */
    color: #ffffff;
}

</style>
