<template>
  <section style="background-color: #00364f; margin: 0px; padding: 5px">
    <div class="row">
      <div
        class="col-xl-9 col-md-9 col-lg-9 d-none d-xl-block d-lg-block d-md-block"
      ></div>
      <div class="col-xl-3 col-md-3 col-lg-3 col-xl-12 text-end">
        <strong class="text-white">
          <i class="fa fa-phone" aria-hidden="true"></i> +91-9528665852</strong
        >
        &nbsp;
        <strong class="text-white">
          <i class="fa fa-envelope" aria-hidden="true"></i> admin@gmail.com</strong
        >
      </div>
    </div>
  </section>

  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    style="
      -webkit-box-shadow: 0px 2px 2px 0px rgba(179, 173, 179, 1);
      -moz-box-shadow: 0px 2px 2px 0px rgba(179, 173, 179, 1);
      box-shadow: 0px 2px 2px 0px rgba(179, 173, 179, 1);
    "
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/home">
        <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 120px" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="dropdown nav-item" data-menu="dropdown">
            <a
              class="dropdown-toggle nav-link d-flex align-items-center"
              href="#"
              data-bs-toggle="dropdown"
              ><span data-i18n="Forms &amp; Tables">Forms &amp; Tables</span></a
            >
          </li> -->
        </ul>
        <div class="d-flex" style="color: black">
          <!-- <p style="font-weight: 500">Become Professional</p>
          &nbsp;&nbsp;
          <p style="font-weight: 500">Get Franchise</p>
          &nbsp;&nbsp;
          <p style="font-weight: 500">Get API</p>
          &nbsp;&nbsp;
          <p style="font-weight: 500">Admin Login</p>
          &nbsp;&nbsp;
          <p style="font-weight: 500">Retailer Login</p>
          &nbsp;&nbsp; -->

          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="dropdown nav-item" data-menu="dropdown">
              <a
                class="nav-link d-flex align-items-center"
                href="https://www.vakilgiri.com/become-professional/"
                ><span style="font-weight: 500; color: black"
                  >Became Professional</span
                ></a
              >
            </li>
            <li class="dropdown nav-item" data-menu="dropdown">
              <a
                class="nav-link d-flex align-items-center"
                href="https://www.vakilgiri.com/get-franchise/"
                ><span style="font-weight: 500; color: black">Get Franchise</span></a
              >
            </li>
            <li class="dropdown nav-item" data-menu="dropdown">
              <a
                class="nav-link d-flex align-items-center"
                href="https://www.vakilgiri.com/get-api/"
                ><span style="font-weight: 500; color: black">Get API</span></a
              >
            </li>
            <li class="nav-item" data-menu="dropdown" v-if="infoData == ''">
              <a
                class="nav-link d-flex align-items-center"
                href="https://csc.vakilgiri.com/admin/home"
                ><span style="font-weight: 500; color: black">Admin</span></a
              >
            </li>
            <li class="nav-item" data-menu="dropdown" v-if="infoData == ''">
              <a
                class="nav-link d-flex align-items-center"
                href="http://csc.vakilgiri.com/retailer/home"
                ><span style="font-weight: 500; color: black">Retailer</span></a
              >
            </li>
            <li class="nav-item" data-menu="dropdown" v-else>
              <a
                class="nav-link d-flex align-items-center"
                href="http://csc.vakilgiri.com/retailer/home"
                ><span style="font-weight: 500; color: black">{{
                  userData.name
                }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Public",
  data: () => ({
    userData: {},
  }),

  created() {
    if (localStorage.getItem("accessToken")) {
      // this.infoData = localStorage.getItem("accessToken");
      this.userData = JSON.parse(localStorage.getItem("userData"));
      console.log(this.userData);
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.head-text {
  color: white;
  font-family: "Poppins", sans-serif;
}
.font {
  font-family: "Poppins", sans-serif;
}
.head-text:hover {
  color: #f21000;
  font-family: "Poppins", sans-serif;
}
</style>
