<template>
  <div class="footer" style="background-color: #00364f">
    <div class="text-white">
      <div
        class="row pt-2 pb-5 font text-center"
        style="padding-left: 5%; padding-right: 5%"
      >
        <div class="col">
          <p>
            By continuing past this page, you agree to our
            <a style="color: #f21000" href="https://www.vakilgiri.com/disclaimer-policy/"
              >Disclaimer Policy</a
            >,
            <a style="color: #f21000" href="https://www.vakilgiri.com/privacy-policy/"
              >Privacy Policy</a
            >,
            <a
              style="color: #f21000"
              href="https://www.vakilgiri.com/confidentiality-statement/"
              >Confidential Policy</a
            >
            and
            <a
              style="color: #f21000"
              href="https://www.vakilgiri.com/satisfaction-gurantee/"
              >Satisfaction Policies</a
            >. © 2023 – Vakilgiri Legtech (India) Private Limited. All rights reserved.
          </p>
          <p>
            <b>Disclaimer-</b> Vakilgiri Legtech in India provides a user-friendly
            platform enabling access to reliable professionals for any legal work. We are
            not a law firm, hence do not provide legal services ourselves. The information
            provided on vakilgiri.com is for the purpose of knowledge only and should not
            be taken upon as legal advice.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 23%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 17.66667%;
  }
}
</style>
