const MasterdistributerRouter = []

if (window.location.pathname.search("/master-distributer") != -1){
  MasterdistributerRouter.push(
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/MasterDistributer/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Home' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'Transection',
        component: () => import('../views/MasterDistributer/Payments/Transection.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Transection' }
          ]
        }
      },
      {
        path: 'tds-reporting',
        name: 'TDSreportingMasterDistributer',
        component: () => import('../views/MasterDistributer/Payments/tdsreport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TDSreportingMasterDistributer' }
          ]
        }
      },
      {
        path: 'wallet-Withdraw-Req',
        name: 'MasterDistributerWalletWithdrawReq',
        component: () => import('../views/MasterDistributer/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributerWalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'retailers',
        name: 'Retailers',
        component: () => import('../views/MasterDistributer/Retailers/Retailers.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Retailers' }
          ]
        }
      },

      {
        path: 'master-distributer-profile',
        name: 'MasterDistributerProfile',
        component: () => import('../views/MasterDistributer/Profile/MasterDistributerProfile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributerProfile' }
          ]
        }
      },
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('../views/MasterDistributer/Projects/Projects.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Projects' }
          ]
        }
      },

      {
        path: 'distributers',
        name: 'Distributers',
        component: () => import('../views/MasterDistributer/Distributers/Distributers.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Distributers' }
          ]
        }
      },
      {
        path: 'service-charges',
        name: 'ServiceCharges',
        component: () => import('../views/MasterDistributer/ServiceCharges/ServiceCharges.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ServiceCharges' }
          ]
        }
      },
      {
        path: 'distributers-view/:id',
        name: 'DistributersView',
        component: () => import('../views/MasterDistributer/Distributers/DistributersView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributersView' }
          ]
        }
      },

      {
        path: 'retailers-view/:id',
        name: 'RetailerView',
        component: () => import('../views/MasterDistributer/Retailers/RetailerView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerView' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'ticket',
        component: () => import('../views/MasterDistributer/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ticket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'TicketReply',
        component: () => import('../views/MasterDistributer/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TicketReply' }
          ]
        }
      },

      {
        path: 'masterdistributer-profile',
        name: 'masterdistributerProfile',
        component: () => import('../views/MasterDistributer/Profile/MasterDistributerProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'masterdistributerProfile' }
          ]
        }
      },



      // {
      //   path: 'masterdistributer-profile',
      //   name: 'masterdistributerProfile',
      //   component: () => import('../views/MasterDistributer/Profile/MasterDistributerProfile.vue'),
      //   meta: {
      //   //   title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'masterdistributerProfile' }
      //     ]
      //   }
      // },



      // {
      //   path: 'login', name: 'Login',
      //   component: () => import('../views/MasterDistributer/auth/Login.vue'),
      //   meta: { headerVisible: 'hide' }
      // },{
      //   path: 'forgotpassword', name: 'ForgotPassword',
      //   component: () => import('../views/MasterDistributer/auth/ForgotPassword.vue'),
      //   meta: { headerVisible: 'hide' }
      // },{
      //   path: 'resetpassword', name: 'ResetPassword',
      //   component: () => import('../views/MasterDistributer/auth/ResetPassword.vue'),
      //   meta: { headerVisible: 'hide' }
      // },{
      //   path: 'twostepverification', name: 'TwoStepVerification',
      //   component: () => import('../views/MasterDistributer/auth/TwoStepVerification.vue'),
      //   meta: { headerVisible: 'hide' }
      // }





      {
        path: 'resetpassword',
        name: 'ResetPassword',
        component: () => import('../views/MasterDistributer/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      {
        path: 'login', name: 'Login',
        component: () => import('../views/MasterDistributer/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/MasterDistributer/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
   
      {
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/MasterDistributer/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      }

    )

  }

  export default MasterdistributerRouter