<template>
  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>


</template>

<script>
export default {
  name: "Footer1",
};
</script>
