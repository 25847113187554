<template>
  <!-- BEGIN: Header-->
  <nav
    class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl"
  >
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <a class="nav-link menu-toggle" href="#"
              ><i class="fa fa-bars" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
        <ul class="nav navbar-nav">
          <button :class="{ 'd-none': $route.path === '/master-distributer/home' }" type="button" class="btn btn-sm" @click="goBack()">
        <img style="width:20px" src="/assets/image/back-button1.png" alt="">
        </button>
          <li class="nav-item d-none d-lg-block">
            <a class="nav-link nav-link-style"
              ><i class="ficon" data-feather="moon"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="d-block d-sm-none text-center" style="width: 100%">
        <center>
          <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 150px" />
        </center>
      </div>
      <ul class="nav navbar-nav align-items-center ms-auto">
        <li class="nav-item dropdown dropdown-user">
          <a
            class="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="user-nav d-sm-flex d-none">
              <span class="user-name fw-bolder font" style="text-transform: uppercase">{{
                userDataProfile.name
              }}</span
              ><span class="user-status font"
                ><span
                  class="font"
                  v-if="userDataProfile.role"
                  style="text-transform: capitalize"
                  >{{ userDataProfile.role.name }}</span
                ></span
              >
            </div>
            



            <span class="d-block d-md-none d-lg-none d-xl-none" style="width:50px;height:50"> 
          <img
                class="round"
                :src="profileImg"
                alt="avatar"
              style="border-radius:100%"
                />
         </span>
         <span class="d-none d-md-block d-lg-block d-xl-block" style="width:40px;height:40">
          <img
                class="round"
                :src="profileImg"
                alt="avatar"
              style="border-radius:100%"
             />
         </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user">
            <!-- <a class="dropdown-item" href="/masterdistributer/masterdistributer-profile"
              ></a
            > -->
            <router-link
              class="dropdown-item"
              to="/master-distributer/masterdistributer-profile"
              ><i class="fa fa-user" aria-hidden="true"></i> Profile</router-link
            >
            <!-- <router-link class="dropdown-item" to="#"
              ><i class="fa fa-envelope"></i> Inbox</router-link
            > -->

            <!-- <a class="dropdown-item" href="#"><i class="fa fa-step-forward"></i> Task</a> -->
            <!-- <a class="dropdown-item" href="#"
              ><i class="me-50" data-feather="message-square"></i> Chats</a
            > -->
            <!-- <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#"><i class="fa fa-cog"></i> Settings</a> -->
            <!-- <a class="dropdown-item" href="#"
              ><i class="me-50" data-feather="credit-card"></i> Pricing</a
            >
            <a class="dropdown-item" href="#"
              ><i class="me-50" data-feather="help-circle"></i> FAQ</a
            > -->
            <a class="dropdown-item" href="#" @click.prevent="logout"
              ><i class="fa fa-power-off"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div v-if="loading == true">
    <Spinner />
  </div>
  <!-- END: Header-->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/professional/comman/Spinner.vue";
export default {
  name: "Header",
  components: {
    Spinner,
  },
  data() {
    return {
      userDataProfile: {},
      profileImg: "",
      loading: false,
    };
  },
  methods: {
    userData() {
      this.$axios
        .post(
          `masterdistributer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log("______________user Profile______________________");
          this.userDataProfile = res.data;
          console.log(this.userDataProfile);
          if (this.userDataProfile.photo != null) {
            // alert('yes photo is founded')
            this.profileImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.userDataProfile.id +
              "/thumbs/" +
              this.userDataProfile.photo;
          } else {
            // alert('photo was not founded')
            this.profileImg = this.$store.state.placeholderImg;
          }
        })
        .catch((error) => {
          if(error.response.data.message == 'Unauthenticated.'){
                  localStorage.removeItem("accessToken");
                  this.$router.push("/master-distributer/login");

                }
        
            });
    },
    logout() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You Want To Logout?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Logout",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .get(`masterdistributer/logout`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                console.log(res.data);

                toast.success("Log Out  Successfully", {
                  autoClose: 1000,
                });
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userData");
                //     toast.success("Log Out  Successfully", {
                //       autoClose: 1000,
                //     });

                delete this.$axios.defaults.headers.common["Authorization"];

                this.$router.push("/master-distributer/login");

                // this.$router.push({ name: "Home" });
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                this.$swal.fire({
                  confirmButtonColor: "#00364f",
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.message,
                });
              });
          }
        });

      // if (confirm("Are you sure you want to log out?")) {

      //   // this.$axios
      //   //   .post("/api/admin/logout")
      //   //   .then((response) => {
      //   //     alert("hi");
      //   //     localStorage.removeItem("accessToken");
      //   //     localStorage.removeItem("userData");
      //   //     toast.success("Log Out  Successfully", {
      //   //       autoClose: 1000,
      //   //     });

      //   //     delete this.$axios.defaults.headers.common["Authorization"];

      //   //     this.$router.push("/login");
      //   //     console.log(response);
      //   //   })
      //   //   .catch((error) => {
      //   //     localStorage.removeItem("accessToken");
      //   //     localStorage.removeItem("userData");
      //   //     delete this.$axios.defaults.headers.common["Authorization"];
      //   //     this.$router.go("/login");
      //   //     console.log(error);
      //   //   });
      // }
    },
    goBack() {
        // Use router to navigate back
        this.$router.go(-1);
      },
    dtttttt() {
      if (JSON.parse(localStorage.getItem("userData"))) {
        console.log(JSON.parse(localStorage.getItem("userData")).role_id);
        if (JSON.parse(localStorage.getItem("userData")).role_id != 6) {
          console.log(JSON.parse(localStorage.getItem("userData")).role_id);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userData");
          this.$router.push("/master-distributer/login");
        }
      } else {
        this.$router.push("/master-distributer/login");
      }
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.dtttttt();
      this.userData();
    }
  },
};
</script>

<style scoped>
.header-navbar.floating-nav {
  margin-top: 0px;
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 205px);
}
.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - 4.4rem - 19px);
}

@media (max-width: 767.98px) {
  .header-navbar.floating-nav {
    width: 100% !important;
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media (max-width: 1201px) {
  .header-navbar.floating-nav {
    width: 100% !important;
  }
}
@media (max-width: 1199.98px) {
  .header-navbar.floating-nav {
    width: 100% !important;
  }
}
.dropdown-item:hover {
  background: #ff00001a;
  color: #f21000;
}

.dropdown-item:hover {
  background: #ff00001a;
  color: #f21000;
}
.dropdown-item {
  font-family: "Poppins", sans-serif;
  color: #00364f;
  /* padding: 10px 15px 10px 15px; */
  line-height: 1;
}
.font {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}
</style>
