export default {
  data() {
    return {
      userPermissions: [],
    };
  },
  methods: {
    fetchFavicon() {
      var faviconUrl =
        "https://uat.vakilgiri.com/backend/system/public/storage/favicon/1/favicon.ico";

      console.log(faviconUrl);
      console.log("=========================================");

      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconUrl;

      // Append the updated favicon link to the head
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    conertNumberToWords(number,role) {
      var url = 'retailer'
        if(role){
          url = role
        }

      this.$axios
        .get(`${url}/numbertotext?number=${number}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.netAmtInWords = res.data.text;
        });
    },
  },
};
