<template>
  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>

  <!-- BEGIN: Footer-->

  <!-- <button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button> -->
  <!-- END: Footer-->
</template>

<script>
export default {
  name: "Footer1",
};
</script>
