const SuperAdminRouter = []

if (window.location.pathname.search("/super-admin") != -1){
  SuperAdminRouter.push(
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: 'home',
        name: 'SuperAdminHome',
        component: () => import('../views/super-admin/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminHome' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'SuperAdminTicket',
        component: () => import('../views/super-admin/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminTicket' }
          ]
        }
      },
      {
        path: 'wallet-Withdraw-Req',
        name: 'WalletWithdrawReq',
        component: () => import('../views/super-admin/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'WalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'TicketReply',
        component: () => import('../views/super-admin/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TicketReply' }
          ]
        }
      },
       
      {
        path: 'admin-view-file/:id',
        name: 'SuperAdminAdminProfile',
        component: () => import('../views/super-admin/admins/AdminView.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminAdminProfile' }
          ]
        }
      },
      {
        path: 'packages',
        name: 'superAdminPackage',
        component: () => import('../views/super-admin/packages/Packages.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'superAdminPackage' }
          ]
        }
      },
      {
        path: 'service-details-view/:id',
        name: 'ServiceDetailsView',
        component: () => import('../views/super-admin/packages/ServiceDetailsView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ServiceDetailsView' }
          ]
        }
      },
      {
        path: 'client-list',
        name: 'SuperAdminClients',
        component: () => import('../views/super-admin/clients/Clients.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminClients' }
          ]
        }
      },
      {
        path: 'client-profile/:id',
        name: 'SuperAdminClientProfile',
        component: () => import('../views/super-admin/clients/Profile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminClientProfile' }
          ]
        }
      },
      {
        path: 'package-services',
        name: 'superAdminPackageServices',
        component: () => import('../views/super-admin/packages/PackageServices.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'superAdminPackageServices' }
          ]
        }
      },
      {
        path: 'projects',
        name: 'AllProjects',
        component: () => import('../views/super-admin/projects/Projects.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AllProjects' }
          ]
        }
        
      },
      // {
      //   path: 'learning-center',
      //   name: 'LearningCenter',
      //   component: () => import('../views/super-admin/LearningCenter/LearningCenter.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'LearningCenter' }
      //     ]
      //   }
        
      // },
      // {
      //   path: 'new-projects',
      //   name: 'NewProjects',
      //   component: () => import('../views/super-admin/projects/Projects.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'NewProjects' }
      //     ]
      //   }
        
      // },
      {
        path: 'project-view/:id',
        name: 'ProjectViewPage',
        component: () => import('../views/super-admin/projects/ProjectView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProjectViewPage' }
          ]
        }
      },
     
      {
        path: 'project-detailes/:id',
        name: 'ProjectDetailes',
        component: () => import('../views/super-admin/projects/ProjectDetailes.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProjectDetailes' }
          ]
        }
      },
      {
        path: 'staff-view/:id',
        name: 'SuperAdminStaffProfile',
        component: () => import('../views/super-admin/staff/StaffView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminStaffProfile' }
          ]
        }
      },
      {
        path: 'office-staff',
        name: 'OfficeStaff',
        component: () => import('../views/super-admin/staff/Staff.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'OfficeStaff' }
          ]
        }
      },
     
      {
        path: 'professionals',
        name: 'SuperAdminProfessional',
        component: () => import('../views/super-admin/professionals/Professionals.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminProfessional' }
          ]
        }
      },
      {
        path: 'professional-profile/:id',
        name: 'SuperAdminProfessionalProfile',
        component: () => import('../views/super-admin/professionals/ProfessionalView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminProfessionalProfile' }
          ]
        }
      },
      {
        path: 'professional-services',
        name: 'ProfassionalServices',
        component: () => import('../views/super-admin/professionals/ProfassionalServices.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfassionalServices' }
          ]
        }
      },
      {
        path: 'retailers',
        name: 'RetailersSuperAdmin',
        component: () => import('../views/super-admin/retailers/Retailers.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailersSuperAdmin' }
          ]
        }
      },
      {
        path: 'retailer-view-file/:id',
        name: 'RetailersSuperAdminProfile',
        component: () => import('../views/super-admin/retailers/RetailerView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailersSuperAdminProfile' }
          ]
        }
      },
     
      {
        path: 'states',
        name: 'States',
        component: () => import('../views/super-admin/masters/States.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'States' }
          ]
        }
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('../views/super-admin/masters/Categories.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Categories' }
          ]
        }
      },
      {
        path: 'bussiness-type',
        name: 'BussinessType',
        component: () => import('../views/super-admin/masters/BussinessType.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'BussinessType' }
          ]
        }
      },
      {
        path: 'modules',
        name: 'Modules',
        component: () => import('../views/super-admin/masters/Modules.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Modules' }
          ]
        }
      },
      {
        path: 'designation',
        name: 'Designation',
        component: () => import('../views/super-admin/masters/Designation.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Designation' }
          ]
        }
      },

 
      {
        path: 'profesional-type',
        name: 'ProfesionalType',
        component: () => import('../views/super-admin/masters/Profesional-Type.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfesionalType' }
          ]
        }
      },
      {
        path: 'on-boarding-charges',
        name: 'OnBoardingCharge',
        component: () => import('../views/super-admin/masters/OnBoardingCharge.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'OnBoardingCharge' }
          ]
        }
      },
      {
        path: 'user-roles',
        name: 'UserRoles',
        component: () => import('../views/super-admin/masters/UserRoles.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'UserRoles' }
          ]
        }
      },
      {
        path: 'staff-permissions',
        name: 'Permissions',
        component: () => import('../views/super-admin/masters/Permissions.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Permissions' }
          ]
        }
      },
      {
        path: 'member-addons',
        name: 'MemberAddons',
        component: () => import('../views/super-admin/masters/MemberAddons.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MemberAddons' }
          ]
        }
      },
      {
        path: 'addon',
        name: 'Addon',
        component: () => import('../views/super-admin/packages/Addon.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Addon' }
          ]
        }
      },
      
      {
        path: 'qualification',
        name: 'Qualification',
        component: () => import('../views/super-admin/masters/Qualification.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Qualification' }
          ]
        }
      },
      {
        path: 'master-steps',
        name: 'MasterSteps',
        component: () => import('../views/super-admin/masters/MasterSteps.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterSteps' }
          ]
        }
      },
      {
        path: 'client-invoice',
        name: 'ClientInvoice',
        component: () => import('../views/super-admin/Invoices/ClientInvoice.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ClientInvoice' }
          ]
        }
      },
      {
        path: 'client-invoice-view/:id',
        name: 'ClientInvoiceView',
        component: () => import('../views/super-admin/Invoices/ClientInvoiceView.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ClientInvoiceView' }
          ]
        }
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('../views/super-admin/masters/Setting.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Setting' }
          ]
        }
      },
      {
        path: 'admins',
        name: 'SuperAdminAdmins',
        component: () => import('../views/super-admin/admins/Admins.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminAdmins' }
          ]
        }
      },
      {
        path: 'create-admins',
        name: 'CreateAdmins',
        component: () => import('../views/super-admin/admins/CreateAdmin.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CreateAdmins' }
          ]
        }
      },
      {
        path: 'update-admins/:id',
        name: 'UpdateAdmins',
        component: () => import('../views/super-admin/admins/CreateAdmin.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'UpdateAdmins' }
          ]
        }
      },
      {
        path: 'super-admin-profile',
        name: 'SuperAdminProfile',
        component: () => import('../views/super-admin/profile/SuperAdminProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminProfile' }
          ]
        }
      },
    
      {
        path: 'master-distributer',
        name: 'SuperAdminMasterDistributers',
        component: () => import('../views/super-admin/MasterDistributers/MasterDistributers.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminMasterDistributers' }
          ]
        }
      },
      {
        path: 'master-distributer-profile/:id',
        name: 'MasterDistributersProfile',
        component: () => import('../views/super-admin/MasterDistributers/Profile.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributersProfile' }
          ]
        }
      },
      {
        path: 'distributer',
        name: 'SuperAdminDistributers',
        component: () => import('../views/super-admin/Distributer/Distributer.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminDistributers' }
          ]
        }
      },
      {
        path: 'distributer-profile/:id',
        name: 'SuperAdminDistributersProfile',
        component: () => import('../views/super-admin/Distributer/Profile.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminDistributersProfile' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'SuperAdminTransections',
        component: () => import('../views/super-admin/Payments/Transection.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'SuperAdminTransections' }
          ]
        }
      },
      {
        path: 'professional-payments',
        name: 'professionalPayments',
        component: () => import('../views/super-admin/Payments/professionalPayments.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'professionalPayments' }
          ]
        }
      },
      {
        path: 'admin-payments',
        name: 'adminPayments',
        component: () => import('../views/super-admin/Payments/adminPayments.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'adminPayments' }
          ]
        }
      },
      {
        path: 'tds-report',
        name: 'TdsReportSuperAdmin',
        component: () => import('../views/super-admin/Payments/tdsReport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TdsReportSuperAdmin' }
          ]
        }
      },
      {
        path: 'testing-active-class',
        name: 'testerContentDeleteable',
        component: () => import('../views/super-admin/testerContentDeleteable/active.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'testerContentDeleteable' }
          ]
        }
      },
      {
        path: 'resetpassword',
        name: 'ResetPassword',
        component: () => import('../views/super-admin/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      {
        path: 'login', name: 'Login',
        component: () => import('../views/super-admin/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/super-admin/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      // {
      //   path: 'resetpassword/:random', name: 'ResetPassword',
      //   component: () => import('../views/super-admin/auth/ResetPassword.vue'),
      //   meta: { headerVisible: 'hide' }
      // },
      {
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/super-admin/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      }

    )

  }

  export default SuperAdminRouter