import { createRouter, createWebHistory } from 'vue-router' 

// import Home from '../views/Home.vue'
// import Home from '../views/Retailer/Home.vue'

import Retailer from '../views/Retailer/Retailer.vue'
import Professional from '../views/Professional/Professional.vue'
import SuperAdmin from '../views/super-admin/SuperAdmin.vue'
import Admin from '../views/Admin/Admin.vue'
import Staff from '../views/staff/Staff.vue'

import Distributer from '../views/distributer/Distributer.vue'
import MasterDistributer from '../views/MasterDistributer/MasterDistributer.vue'
import Publicc from '../views/Public/Public.vue'

import retailer from '@/router/retailer'
import professional from '@/router/professional'
import superAdmin from '@/router/super-admin'
import staff from '@/router/staff'
import admin from '@/router/admin'
import distributer from '@/router/distributer'
import masterDistributer from '@/router/master-distributer'
import publicc from '@/router/public'

const routes = [  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/retailer', name: 'Retailer',
  //   component: Retailer
  // },  
  // {
  //   path: '/retailer',
  //   component: Retailer,
  //   children: [
  //     ...retailer,
  //   ],
  // },
  // {
  //   path: '/professional',
  //   component: Professional,
  //   children: [
  //     ...professional,
  //   ],
  // },
  // {
  //   path: '/super-admin',
  //   component: SuperAdmin,
  //   children: [
  //     ...superAdmin,
  //   ],
  // },
  // {
  //   path: '/admin',
  //   component: Admin,
  //   children: [
  //     ...admin,
  //   ],
  // },
  // {
  //   path: '/staff',
  //   component: Staff,
  //   children: [
  //     ...staff,
  //   ],
  // },
  {
    path: '/error-404', name: 'Error404',
    component: () => import('@/views/Error/Error404.vue'),
    meta: {
      headerVisible: 'hide',
    },
  },{
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/error-404',
  }
]

routes.push({
  path: '/',
  component: Publicc,
  children: [
    ...publicc,
  ],
})
if (window.location.pathname.search("/professional") != -1){
  routes.push({
                path: '/professional',
                component: Professional,
                children: [
                  ...professional,
                ],
              })
}

if (window.location.pathname.search("/retailer") != -1){
  routes.push({
                path: '/retailer',
                component: Retailer,
                children: [
                  ...retailer,
                ],
              })
}

if (window.location.pathname.startsWith("/super-admin") != -1){
  routes.push({
                path: '/super-admin',
                component: SuperAdmin,
                children: [
                  ...superAdmin,
                ],
              })
}

if (window.location.pathname.startsWith("/distributer") != -1){
  routes.push({
                path: '/distributer',
                component: Distributer,
                children: [
                  ...distributer,
                ],
              })
}
if (window.location.pathname.startsWith("/master-distributer") != -1){
  routes.push({
                path: '/master-distributer',
                component: MasterDistributer,
                children: [
                  ...masterDistributer,
                ],
              })
}

if (window.location.pathname.startsWith("/admin") != -1){
  routes.push({
                path: '/admin',
                component: Admin,
                children: [
                  ...admin,
                ],
              })
}

if (window.location.pathname.startsWith("/staff") != -1){
  routes.push({
                path: '/staff',
                component: Staff,
                children: [
                  ...staff,
                ],
              })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})



// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'admin-auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role_id : null))
//   }

//   return next()
// })

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login',
                        '/forgotpassword',
                        '/resetpassword',
                        '/twostepverification',
                        // '/register',
                      ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = {name: 'hhhhh'} // localStorage.getItem('user');

  if (authRequired && !loggedIn && !to.meta.redirectIfLoggedIn) {
    return next('/login');
  }

  next();
})

export default router
