const RetailerRouter = []

if (window.location.pathname.search("/retailer") != -1){
  RetailerRouter.push(
    {
      path: '/datata',
      name: 'Goto1',
      component: () => import('../views/Retailer/DashBoard/redirect.vue'),
      meta: {
      //   title: 'home title',
        redirectIfLoggedIn: 'true',
        breadCrumb: [
          { name: 'Goto1' }
        ]
      }
    },
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Retailer/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Add New' }
          ]
        }
      },
      {
        path: 'all-services',
        name: 'AllServices',
        component: () => import('../views/Retailer/AllServices/Services.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AllServices' }
          ]
        }
      },

      {
        path: 'tds-reporting',
        name: 'TdsReportingRetailer',
        component: () => import('../views/Retailer/payments/tdsreport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TdsReportingRetailer' }
          ]
        }
      },
      {
        path: 'individual-3',
        name: 'Individual3',
        component: () => import('../views/Retailer/services/Individual/Individual3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Individual3' }
          ]
        }
      },
      {
        path: 'individual-4',
        name: 'Individual4',
        component: () => import('../views/Retailer/services/Individual/Individual4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Individual4' }
          ]
        }
      },
      {
        path: 'individual-2',
        name: 'Individual2',
        component: () => import('../views/Retailer/services/Individual/Individual2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Individual2' }
          ]
        }
      },





      {
        path: 'itr',
        name: 'Itr',
        component: () => import('../views/Retailer/services/itr/Itr.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Itr' }
          ]
        }
      },
      {
        path: 'itr2',
        name: 'Itr2',
        component: () => import('../views/Retailer/services/itr/Itr2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Itr2' }
          ]
        }
      },
      {
        path: 'itr3',
        name: 'Itr3',
        component: () => import('../views/Retailer/services/itr/Itr3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ITR3' }
          ]
        }
      },
      {
        path: 'itr4',
        name: 'Itr4',
        component: () => import('../views/Retailer/services/itr/Itr4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ITR4' }
          ]
        }
      },
      {
        path: 'itr5/:id',
        name: 'Itr5',
        component: () => import('../views/Retailer/services/itr/Itr5.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ITR5' }
          ]
        }
      },
      {
        path: 'itr6/:id',
        name: 'Itr6',
        component: () => import('../views/Retailer/services/itr/Itr6.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ITR6' }
          ]
        }
      },
      {
        path: 'thank-you',
        name: 'ThankYou',
        component: () => import('../views/Retailer/services/ThankYou.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ThankYou' }
          ]
        }
      },
      {
        path: 'client',
        name: 'Client',
        component: () => import('../views/Retailer/clients/Clients.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'All Client' }
          ]
        }
      },
      {
        path: 'business-profile/:id',
        name: 'businessProfile',
        component: () => import('../views/Retailer/clients/businessProfile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'businessProfile' }
          ]
        }
      },
      {
        path: 'clientview/:id',
        name: 'ClientView',
        component: () => import('../views/Retailer/clients/ClientShow.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Client View' }
          ]
        }
      },
      {
        path: 'clientservice',
        name: 'ClientService',
        component: () => import('../views/Retailer/clientservices/ClientServices.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'All Client Services' }
          ]
        }
      },
      {
        path: 'clientservice/view/:id',
        name: 'ClientServiceView',
        component: () => import('../views/Retailer/clientservices/ClientServicesView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Client Services View' }
          ]
        }
      },
      {
        path: 'test',
        name: 'testComponent',
        component: () => import('../views/Retailer/test.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Test' }
          ]
        }
      },
      // for llp services
      {
        path: 'llp-2',
        name: 'Llp2',
        component: () => import('../views/Retailer/services/llp/llp2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Llp2' }
          ]
        }
      },
      {
        path: 'llp-3',
        name: 'Llp3',
        component: () => import('../views/Retailer/services/llp/llp3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Llp3' }
          ]
        }
      },
      {
        path: 'llp-4',
        name: 'Llp4',
        component: () => import('../views/Retailer/services/llp/llp4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Llp4' }
          ]
        }
      },
      // for all partnership services 
      {
        path: 'partnership-2',
        name: 'Partnership2',
        component: () => import('../views/Retailer/services/partnership/partnership2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Partnership2' }
          ]
        }
      },
      {
        path: 'partnership-3',
        name: 'Partnership3',
        component: () => import('../views/Retailer/services/partnership/partnership3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Partnership3' }
          ]
        }
      },
      {
        path: 'partnership-4',
        name: 'Partnership4',
        component: () => import('../views/Retailer/services/partnership/partnership4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Partnership4' }
          ]
        }
      },
      
      // for all society services 
      {
        path: 'society-2',
        name: 'Society2',
        component: () => import('../views/Retailer/services/society/society2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Society2' }
          ]
        }
      },
      {
        path: 'society-3',
        name: 'Society3',
        component: () => import('../views/Retailer/services/society/society3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Society3' }
          ]
        }
      },
      {
        path: 'society-4',
        name: 'Society4',
        component: () => import('../views/Retailer/services/society/society4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Society4' }
          ]
        }
      },
      // {
      //   path: 'society5/:id',
      //   name: 'society5Component',
      //   component: () => import('../views/Retailer/services/society/society5.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'society5' }
      //     ]
      //   }
      // },
      // {
      //   path: 'society6/:id',
      //   name: 'society6Component',
      //   component: () => import('../views/Retailer/services/society/society6.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'society6' }
      //     ]
      //   }
      // },
      // for all aop services 
      {
        path: 'aop-2',
        name: 'Aop2',
        component: () => import('../views/Retailer/services/aop-boi/aop2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Aop2' }
          ]
        }
      },
      {
        path: 'aop-3',
        name: 'Aop3',
        component: () => import('../views/Retailer/services/aop-boi/aop3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Aop3' }
          ]
        }
      },
      {
        path: 'aop-4',
        name: 'Aop4',
        component: () => import('../views/Retailer/services/aop-boi/aop4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Aop4' }
          ]
        }
      },
      // {
      //   path: 'aop5/:id',
      //   name: 'aop5Component',
      //   component: () => import('../views/Retailer/services/aop-boi/aop5.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'aop5' }
      //     ]
      //   }
      // },
      // {
      //   path: 'aop6/:id',
      //   name: 'aop6Component',
      //   component: () => import('../views/Retailer/services/aop-boi/aop6.vue'),
      //   meta: {
      //     // title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'aop6' }
      //     ]
      //   }
      // },
      // for all huf services 
      {
        path: 'huf-2',
        name: 'Huf2',
        component: () => import('../views/Retailer/services/huf/huf2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Huf2' }
          ]
        }
      },
      {
        path: 'huf-3',
        name: 'Huf3',
        component: () => import('../views/Retailer/services/huf/huf3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Huf3' }
          ]
        }
      },
      {
        path: 'huf-4',
        name: 'Huf4',
        component: () => import('../views/Retailer/services/huf/huf4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Huf4' }
          ]
        }
      },
     
       // for all company_public services 
      
      
       {
        path: 'company-public-2',
        name: 'CompanyPublic2',
        component: () => import('../views/Retailer/services/company_public/company_public2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPublic2' }
          ]
        }
      },
      {
        path: 'company-public-3',
        name: 'CompanyPublic3',
        component: () => import('../views/Retailer/services/company_public/company_public3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPublic3' }
          ]
        }
      },
      {
        path: 'company-public-4',
        name: 'CompanyPublic4',
        component: () => import('../views/Retailer/services/company_public/company_public4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPublic4' }
          ]
        }
      },
     
       // for all company_private services 
       {
        path: 'company-private-2',
        name: 'CompanyPrivate2',
        component: () => import('../views/Retailer/services/company_private/company_private2.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPrivate2' }
          ]
        }
      },
      
      {
        path: 'company-private-3',
        name: 'CompanyPrivate3',
        component: () => import('../views/Retailer/services/company_private/company_private3.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPrivate3' }
          ]
        }
      },
      {
        path: 'company-private-4',
        name: 'CompanyPrivate4',
        component: () => import('../views/Retailer/services/company_private/company_private4.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CompanyPrivate4' }
          ]
        }
      },
      
// propriterships

{
  path: 'proprietorship',
  name: 'ProprietorShip',
  component: () => import('../views/Retailer/ProprietorShip/ProprietorShip.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ProprietorShip' }
    ]
  }
},


{
  path: 'proprietorship-2',
  name: 'ProprietorShip2',
  component: () => import('../views/Retailer/ProprietorShip/ProprietorShip2.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ProprietorShip2' }
    ]
  }
},

{
  path: 'proprietorship-3',
  name: 'ProprietorShip3',
  component: () => import('../views/Retailer/ProprietorShip/ProprietorShip3.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ProprietorShip3' }
    ]
  }
},


{
  path: 'partner-ship-services',
  name: 'PartnerShipService',
  component: () => import('../views/Retailer/PartnerShipServices/PartnerShipService.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'PartnerShipService' }
    ]
  }
},
{
  path: 'partner-ship-services2',
  name: 'PartnerShipService2',
  component: () => import('../views/Retailer/PartnerShipServices/PartnerShipService2.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'PartnerShipService2' }
    ]
  }
},
{
  path: 'partner-ship-services3',
  name: 'PartnerShipService3',
  component: () => import('../views/Retailer/PartnerShipServices/PartnerShipService3.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'PartnerShipService3' }
    ]
  }
},
{
  path: 'iso',
  name: 'ISO',
  component: () => import('../views/Retailer/Iso/Iso.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ISO' }
    ]
  }
},
{
  path: 'iso2',
  name: 'ISO2',
  component: () => import('../views/Retailer/Iso/Iso2.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ISO2' }
    ]
  }
},
{
  path: 'iso3',
  name: 'ISO3',
  component: () => import('../views/Retailer/Iso/Iso3.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'ISO3' }
    ]
  }
},

{
  path: 'trademark',
  name: 'Trademark',
  component: () => import('../views/Retailer/Trademark/Trademark.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'Trademark' }
    ]
  }
},
{
  path: 'trademark-2',
  name: 'Trademark2',
  component: () => import('../views/Retailer/Trademark/Trademark2.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'Trademark2' }
    ]
  }
},
{
  path: 'trademark-3',
  name: 'Trademark3',
  component: () => import('../views/Retailer/Trademark/Trademark3.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'Trademark3' }
    ]
  }
},
{
  path: 'udyam',
  name: 'MyService',
  component: () => import('../views/Retailer/Udyam/Udyam.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'MyService' }
    ]
  }
},
{
  path: 'udyam-2',
  name: 'MyService2',
  component: () => import('../views/Retailer/Udyam/Udyam2.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'MyService2' }
    ]
  }
},
{
  path: 'udyam-3',
  name: 'MyService3',
  component: () => import('../views/Retailer/Udyam/Udyam3.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'true',
    breadCrumb: [
      { name: 'MyService3' }
    ]
  }
},
{
  path: 'payment-screen',
  name: 'paymentScreen',
  component: () => import('../views/Retailer/retailerprofile/paymentScreen.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'false',
    breadCrumb: [
      { name: 'paymentScreen' }
    ]
  }
},


      
{
        path: 'login', name: 'Login',
        component: () => import('../views/Retailer/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/Retailer/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'resetpassword', name: 'ResetPassword',
        component: () => import('../views/Retailer/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/Retailer/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'termcondition', name: 'TermCondition',
        component: () => import('../views/Retailer/TermCondition.vue'),
        meta: { headerVisible: 'hide' }
      },
      // clint Kyc Page 
      {
        path: 'client_kyc/:id',
        name: 'Client_Kyc',
        component: () => import('../views/Retailer/clients/ClientKyc.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Client_Kyc' }
          ]
        }
      },
      {
        path: 'clientprofile/:id',
        name: 'ClientProfile',
        component: () => import('../views/Retailer/clients/ClientProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ClientProfile' }
          ]
        }
      },
      
      {
        path: 'packages',
        name: 'packages',
        component: () => import('../views/Retailer/packages/Package.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'packages' }
          ]
        }
      },
      
      {
        path: 'project',
        name: 'RetailerProject',
        component: () => import('../views/Retailer/projects/Project.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerProject' }
          ]
        }
      },

   
      {
        path: 'viewproject/:id', 
        name: 'ViewProjectRetailer',
        component: () => import('../views/Retailer/projects/viewProject.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ViewProjectRetailer' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'ticket',
        component: () => import('../views/Retailer/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ticket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'RetailerTicketViewTicket',
        component: () => import('../views/Retailer/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerTicketViewTicket' }
          ]
        }
      },
        
      {
        path: 'learningcenter',
        name: 'learningcenter',
        component: () => import('../views/Retailer/learningcenter/LearningCenter.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'learningcenters' }
          ]
        }
      },
      {
        path: 'wallet-Withdraw-Req',
        name: 'RetailerWalletWithdrawReq',
        component: () => import('../views/Retailer/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerWalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'retailerprofile',
        name: 'retailerprofile',
        component: () => import('../views/Retailer/retailerprofile/RetailerProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'learningcenters' }
          ]
        }
      },
      {
        path: 'payment/:id',
        name: 'payment',
        component: () => import('../views/Retailer/payments/payment.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Payment' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'Transection',
        component: () => import('../views/Retailer/payments/Transection.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Transection' }
          ]
        }
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: () => import('../views/Retailer/invoice/Invoice.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'invoice' }
          ]
        }
      },

      {
        path: 'invoicerecipt/:id',
        name: 'invoicerecipt',
        component: () => import('../views/Retailer/invoice/InvoiceRecipt.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'invoicerecipt' }
          ]
        }
      },

      {
        path: 'full-screenpage-invoice/:id',
        name: 'fullscreenpageInvoice',
        component: () => import('../views/Retailer/invoice/fullscreenpageInvoice.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
       headerVisible: 'hide' 
        }
      },
      {
        path: 'payment-recipt',
        name: 'PaymentRecipt',
        component: () => import('../views/Retailer/payments/PaymentRecipt.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'PaymentRecipt' }
          ]
        }
      },




//  common page k liye servicemodule ke route

{
  path: 'service-module-partnership-registration',
  name: 'ServiceModulePartnershipRegistration',
  component: () => import('../views/Retailer/serviceModules/PartnershipRegistration/service-module-partnership-registration.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'false',
    breadCrumb: [
      { name: 'ServiceModulePartnershipRegistration' }
    ]
  }
},



{
  path: 'income-tax-return-filling',
  name: 'IncomeTaxReturnFilling',
  component: () => import('../views/Retailer/serviceModules/IncomeTaxReturnFilling/income-tax-return-filling.vue'),
  meta: {
    // title: 'home title',
    redirectIfLoggedIn: 'false',
    breadCrumb: [
      { name: 'IncomeTaxReturnFilling' }
    ]
  }
},

// end route 
     

    // {
    //   path: 'retailer',
    //   name: 'retailer',
    //   component: () => import('@/views/leagal-csc/retailer/Dashboard.vue'),
    // },
    // {
    //   path: 'retailer/client',
    //   name: 'retailer-client-user',
    //   component: () => import('@/views/leagal-csc/retailer/user/Client.vue'),
    //   meta: {
    //     pageTitle: 'Retailer',
    //     breadcrumb: [
    //       {
    //         text: 'Client',
    //       },
    //       {
    //         text: 'Client List',
    //         active: true,
    //       },
    //     ],
    //   },



    // }
)

}

export default RetailerRouter