<template>
  <div>
    <div v-if="$route.meta.headerVisible !== 'hide'">
      <Header />
      <Sidebar />
    </div>
    <router-view/>
    <div v-if="$route.meta.headerVisible !== 'hide'">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '../../components/retailer/comman/Header.vue'
import Sidebar from '../../components/retailer/comman/Sidebar.vue'
import Footer from '../../components/retailer/comman/Footer.vue'

export default {
  name: 'Retailer',
  components: {
    Header,
    Sidebar,
    Footer,
  },
  // data() {
  //   return {
  //     auth: 'true'
  //   }
  // },
  // mounted(){
  //   alert(4)
  // },
  // beforeMount() {
  //   alert(3)
  // },
  // beforeCreate() {
  //   alert(1)
  // },
  created(){
    // alert(2)
    // alert(this.$route.name)
    // if(this.$route.name === 'Login' || this.$route.name === 'ForgotPassword' || this.$route.name === 'Error404'
    // || this.$route.name === 'ResetPassword' || this.$route.name === 'TwoStepVerification' || this.$route.name === 'undefined'){
    //   this.auth = 'false'
    // }
  }
}
</script>

<style>
  .table-scroll{
    overflow-y:auto;
  }
  .navbar-floating .header-navbar-shadow {
    background: transparent;
  }
  .form-control:focus {
    border-color: #00364f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  }
  .main-menu.menu-light .navigation > li.active > a {
      background: #f21300;
      box-shadow: 0 0 10px 1px rgb(190 187 181 / 94%);
      color: #fff;
      font-weight: 400;
      border-radius: 4px;
  }
</style>