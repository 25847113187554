<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <router-link class="navbar-brand" to="/super-admin/home">
            <span class="brand-logo">
              <img
                v-if="show == 'false'"
                src="/app-assets/images/vakilgiri-logo.png"
                style="max-width: 120px"
              />
              <img v-else src="/assets/image/fab2.png" style="max-width: 30px" />
            </span>
          </router-link>
        </li>
        <li class="nav-item nav-toggle">
          <!-- @click="show == 'false' ? logoIconeChange('true') : logoIconeChange('false')" -->
          <a
            class="nav-link modern-nav-toggle pe-0"
            data-bs-toggle="collapse"
           
          >
            <img
              class="collapse-toggle-icon font-medium-4 text-primary"
              style="width: 15px"
              src="/assets/image/more.png"
              alt=""
            />
          </a>
        </li>
      </ul>
    </div>

    <div class="main-menu-content">
      <ul
    class="navigation navigation-main"
    id="main-menu-navigation"
    data-menu="menu-navigation"
  >
    <!-- Existing menu items... -->


    <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/home' }">

          <router-link class="d-flex align-items-center" to="/super-admin/home">
            &nbsp; &nbsp;
            <img style="width: 20px" :src="($route.path === '/super-admin/home') ? '/assets/image/active-icon/dashboard.png' : '/assets/image/dashboard.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Dashboard</span>
          </router-link>
        </li>


    <li class="nav-item has-sub" :class="{ 'open': $route.path.startsWith('/super-admin/platform') }">
      <a
        class="d-flex align-items-center"
        href="#"
        data-bs-toggle="collapse"
        data-bs-target="#Platform"
        aria-expanded="false"
        aria-controls="Platform"
      >
      &nbsp; &nbsp;
        <img style="width: 20px" src="/assets/image/platform.png" alt="" />
        &nbsp; &nbsp;&nbsp;&nbsp;
        <span class="menu-title text-truncate" data-i18n="User">Platform Module</span>
      </a>

      <div class="collapse" id="Platform" >
        <ul class="menu-content">
         
          <li :class="{ 'active': $route.path === '/super-admin/create-admins' }">
            <router-link
              class="d-flex align-items-center"
              to="/super-admin/create-admins?portal=true"
            >
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/create-admins') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
              <span class="menu-item text-truncate" data-i18n="Account">Create Admin</span>
            </router-link>
          </li>

          <li  :class="{ 'active': $route.path === '/super-admin/admins' }">
          <router-link class="d-flex align-items-center" to="/super-admin/admins?portal=true">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/admins') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Admin Services</span>
          </router-link>
        </li>

         
        </ul>
      </div>
    </li>





        <li class="nav-item has-sub" style="">
          <a
            class="d-flex align-items-center"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target="#Franchise"
            aria-expanded="false"
            aria-controls="Franchise"
          >
            <!-- <strong>
              <i
                class="fab fa-modx"
                aria-hidden="true"
                style="font-size: 1.1rem; margin-bottom: 0px"
              ></i
            ></strong> -->
            &nbsp; &nbsp;
            <img style="width: 20px" src="/assets/image/franchise.png" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="User"
              >Franchise Module</span
            ></a
          >

          <div class="collapse" id="Franchise" >
            <ul class="menu-content">
              <li :class="{ 'active': $route.path === '/super-admin/create-admins' }">
                <a
                  class="d-flex align-items-center"
                  href="/super-admin/create-admins?portal=false"
                >
                &nbsp; &nbsp;&nbsp; &nbsp;
                  <img style="width: 10px" :src="($route.path === '/super-admin/create-admins') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
           
                  &nbsp; &nbsp;&nbsp;
                  <span class="menu-item text-truncate" data-i18n="Account"
                    >Create Admin</span
                  >
                </a>
              </li>


              
              <li :class="{ 'active': $route.path === '/super-admin/admins' }">
                <a
                  :href="`/super-admin/admins?portal=false`"
                  class="d-flex align-items-center"
                >
                &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/admins') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
                  <span class="menu-item text-truncate" data-i18n="Account"
                    >Admin Services</span
                  >
                </a>
              </li>
           
              <li  :class="{ 'active': $route.path === '/super-admin/master-distributer' }">
          <router-link class="d-flex align-items-center" to="/super-admin/master-distributer">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/master-distributer') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Master Distributor</span>
          </router-link>
        </li>

              <li  :class="{ 'active': $route.path === '/super-admin/distributer' }">
          <router-link class="d-flex align-items-center" to="/super-admin/distributer">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/distributer') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Distributor</span>
          </router-link>
        </li>
            </ul>
          </div>
        </li>

        
        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/client-list' }">
          <router-link class="d-flex align-items-center" to="/super-admin/client-list">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/client-list') ? '/assets/image/active-icon/clients.png' : '/assets/image/clients.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Clients</span>
          </router-link>
        </li>
 
        
        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/retailers' }">
          <router-link class="d-flex align-items-center" to="/super-admin/retailers">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/retailers') ? '/assets/image/active-icon/retailer.png' : '/assets/image/retailer.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Retailers</span>
          </router-link>
        </li>
       

        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/projects' }">
          <router-link class="d-flex align-items-center" to="/super-admin/projects">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/projects') ? '/assets/image/active-icon/project.png' : '/assets/image/project.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Projects</span>
          </router-link>
        </li>


        <li class="nav-item has-sub" style="">
          <a
            class="d-flex align-items-center"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target="#servicePack"
            aria-expanded="false"
            aria-controls="servicePack"
          >
          &nbsp; &nbsp;

            <img style="width: 20px" src="/assets/image/services.png" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="User"
              >Service & Package</span
            ></a
          >

          <div class="collapse" id="servicePack" >
            <ul class="menu-content">
             


              <li  :class="{ 'active': $route.path === '/super-admin/packages' }">
          <router-link class="d-flex align-items-center" to="/super-admin/packages">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/packages') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Packages</span>
          </router-link>
        </li>


        <li  :class="{ 'active': $route.path === '/super-admin/package-services' }">
          <router-link class="d-flex align-items-center" to="/super-admin/package-services">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/package-services') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Services</span>
          </router-link>
        </li>
        <li  :class="{ 'active': $route.path === '/super-admin/member-addons' }">
          <router-link class="d-flex align-items-center" to="/super-admin/member-addons">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/member-addons') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Service Addons</span>
          </router-link>
        </li>


        <li  :class="{ 'active': $route.path === '/super-admin/modules' }">
          <router-link class="d-flex align-items-center" to="/super-admin/modules">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/modules') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Modules</span>
          </router-link>
        </li>

        <li  :class="{ 'active': $route.path === '/super-admin/master-steps' }">
          <router-link class="d-flex align-items-center" to="/super-admin/master-steps">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/master-steps') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Steps</span>
          </router-link>
        </li>


             
        
             
            </ul>
          </div>
        </li>

        
        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/professionals' }">
          <router-link class="d-flex align-items-center" to="/super-admin/professionals">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/professionals') ? '/assets/image/active-icon/professsional.png' : '/assets/image/professsional.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Professional</span>
          </router-link>
        </li>



        
        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/office-staff' }">
          <router-link class="d-flex align-items-center" to="/super-admin/office-staff">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/office-staff') ? '/assets/image/active-icon/staff.png' : '/assets/image/staff.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Staff</span>
          </router-link>
        </li>


   

        <li class="nav-item has-sub" style="">
          <a
            class="d-flex align-items-center"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target="#Paymnets"
            aria-expanded="false"
            aria-controls="Paymnets"
          >
          &nbsp; &nbsp;
            <img style="width: 20px" src="/assets/image/payments.png" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="User">Payments</span></a
          >

          <div class="collapse" id="Paymnets" >
            <ul class="menu-content">


              <li  :class="{ 'active': $route.path === '/super-admin/admin-payments' }">
          <router-link class="d-flex align-items-center" to="/super-admin/admin-payments">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/admin-payments') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Admin Payments</span>
          </router-link>
        </li>


        <li  :class="{ 'active': $route.path === '/super-admin/professional-payments' }">
          <router-link class="d-flex align-items-center" to="/super-admin/professional-payments">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/professional-payments') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Professional Payments</span>
          </router-link>
        </li>


        <li  :class="{ 'active': $route.path === '/super-admin/transections-reporting' }">
          <router-link class="d-flex align-items-center" to="/super-admin/transections-reporting">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/transections-reporting') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Transactions & Reportings</span>
          </router-link>
        </li>
       
            </ul>
          </div>
        </li>

        <li class="nav-item has-sub" style="">
          <a
            class="d-flex align-items-center"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target="#master"
            aria-expanded="false"
            aria-controls="master"
          >
          &nbsp; &nbsp;
            <img style="width:20px" src="/assets/image/master.png" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="User">Master</span></a
          >

          <div class="collapse" id="master" >
            <ul class="menu-content">
           

              
              <li  :class="{ 'active': $route.path === '/super-admin/categories' }">
          <router-link class="d-flex align-items-center" to="/super-admin/categories">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/categories') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Categories</span>
          </router-link>
        </li>


            
        <li  :class="{ 'active': $route.path === '/super-admin/states' }">
          <router-link class="d-flex align-items-center" to="/super-admin/states">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/states') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">States</span>
          </router-link>
        </li>

        <li  :class="{ 'active': $route.path === '/super-admin/bussiness-type' }">
          <router-link class="d-flex align-items-center" to="/super-admin/bussiness-type">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/bussiness-type') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Bussiness Type</span>
          </router-link>
        </li>

             
        <li  :class="{ 'active': $route.path === '/super-admin/designation' }">
          <router-link class="d-flex align-items-center" to="/super-admin/designation">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/designation') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Designation</span>
          </router-link>
        </li>
        

        <li  :class="{ 'active': $route.path === '/super-admin/on-boarding-charges' }">
          <router-link class="d-flex align-items-center" to="/super-admin/on-boarding-charges">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/on-boarding-charges') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">On Boarding Charges</span>
          </router-link>
        </li>
        
        <li  :class="{ 'active': $route.path === '/super-admin/profesional-type' }">
          <router-link class="d-flex align-items-center" to="/super-admin/profesional-type">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/profesional-type') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Profesional Type</span>
          </router-link>
        </li>
        
            
        <li  :class="{ 'active': $route.path === '/super-admin/qualification' }">
          <router-link class="d-flex align-items-center" to="/super-admin/qualification">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/qualification') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Qualification</span>
          </router-link>
        </li>
               
           
        <li  :class="{ 'active': $route.path === '/super-admin/staff-permissions' }">
          <router-link class="d-flex align-items-center" to="/super-admin/staff-permissions">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/staff-permissions') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Staff Permissions</span>
          </router-link>
        </li>
            
           
        <li  :class="{ 'active': $route.path === '/super-admin/user-roles' }">
          <router-link class="d-flex align-items-center" to="/super-admin/user-roles">
            &nbsp; &nbsp;&nbsp; &nbsp;
            <img style="width: 10px" :src="($route.path === '/super-admin/user-roles') ? '/assets/image/active-icon/circle.png' : '/assets/image/circle.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Departments</span>
          </router-link>
        </li>
            
           
            </ul>
          </div>
        </li>

    

        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/ticket' }">
          <router-link class="d-flex align-items-center" to="/super-admin/ticket">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/ticket') ? '/assets/image/active-icon/tickets.png' : '/assets/image/tickets.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Ticket</span>
          </router-link>
        </li>

        
        <li class="nav-item" :class="{ 'active': $route.path === '/super-admin/wallet-Withdraw-Req' }">
          <router-link class="d-flex align-items-center" to="/super-admin/wallet-Withdraw-Req">
            &nbsp; &nbsp;
            <img style="width: 20px"  :src="($route.path === '/super-admin/wallet-Withdraw-Req') ? '/assets/image/active-icon/wallet.png' : '/assets/image/wallet.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Wallet withdrawal</span>
          </router-link>
        </li>
      
   
  </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
export default {
  name: "SuperAdminSideBar",
  components: {},
  data() {
    return {
      show: "false",
    };
  },
  methods: {
    logoIconeChange(value) {
      this.show = value;
    },
  },
};
</script>

  <!-- Integrated styles -->
  <style scoped>
    .main-menu.menu-light .navigation > li ul .active {
      color: #00364f !important;
      background-color: #f21300 !important;
      box-shadow: none !important;
    }

    .main-menu.menu-light .navigation > li.active > a {
      background: #f21300;
      box-shadow: none !important;
      color: #fff;
      font-weight: 400;
      border-radius: 4px;
    }

    .nav-item {
      font-family: "Poppins", sans-serif;
    }

    .nav-item.active {
      background-color: #f21000;
      color: white;
      box-shadow: 0px 0px 0px 0px solid white;
    }

    .nav-item.active a {
      background-color: #f21000;
      color: white;
      box-shadow: none;
    }

    .main-menu.menu-light .navigation li a {
      color: #00364f;
      padding: 10px 15px 10px 15px;
      line-height: 1.45;
    }

    .main-menu:not(.expanded) .navigation li.active a {
      background: #f21000 !important;
      /* box-shadow: none; */
      color: #ffffff;
    }



    .main-menu.menu-light .navigation > li > a {
    margin: 0px;
}



.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-light .navigation > li.sidebar-group-active > a {
    color: #565360;
    background: whitesmoke;
    border-radius: 0px;
}


.main-menu.menu-light .navigation > li ul .active {
    background: #f21000 !important;
    box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
    border-radius: 0px;
    z-index: 1;
}
  </style>