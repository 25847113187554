export default [
    {
        path: '/',
        redirect: '/home',
    },
    
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Professional/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Home' }
          ]
        }
      },
      {
        path: 'transections-reporting',
        name: 'ProfessionalTransections',
        component: () => import('../views/Professional/Payments/Transection.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalTransections' }
          ]
        }
      },
      {
        path: 'tds-reporting',
        name: 'ProfessionalTransectionsTsd',
        component: () => import('../views/Professional/Payments/tdsreport.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalTransectionsTsd' }
          ]
        }
      },
      {
        path: 'projects',
        name: 'ProfessionalProjects',
        component: () => import('../views/Professional/projects/Project.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalProjects' }
          ]
        }
      },
      {
        path: 'wallet-Withdraw-Req',
        name: 'ProfessionalWalletWithdrawReq',
        component: () => import('../views/Professional/walletWithdrawReq/walletWithdrawReq.vue'),
        meta: { 
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalWalletWithdrawReq' }
          ]
        }
      },
      {
        path: 'project-view/:id',
        name: 'ProfessionalProjectsView',
        component: () => import('../views/Professional/projects/ProjectView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalProjectsView' }
          ]
        }
      },
      {
        path: 'learningcenter',
        name: 'learningcenter',
        component: () => import('../views/Professional/learningcenter/LearningCenter.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'learningcenters' }
          ]
        }
      },
      {
        path: 'professional-profile',
        name: 'ProfassionalProfile',
        component: () => import('../views/Professional/profile/ProfessionalProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfassionalProfile' }
          ]
        }
      },
      {
        path: 'professional-services',
        name: 'ProfessionalServices',
        component: () => import('../views/Professional/Services/ProfessionalServices.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalServices' }
          ]
        }
      },
      {
        path: 'services',
        name: 'ProfessionalallServices',
        component: () => import('../views/Professional/Services/Services.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalallServices' }
          ]
        }
      },

      {
        path: 'reimbursements',
        name: 'Reimbursements',
        component: () => import('../views/Professional/Reimbursements/Reimbursements.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Reimbursements' }
          ]
        }
      },
      {
        path: 'certificate',
        name: 'CertificateProfessional',
        component: () => import('../views/Professional/certificate/certificate.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'CertificateProfessional' }
          ]
        }
      },

     {
        path: 'invoice',
        name: 'ProfessionalInvoces',
        component: () => import('../views/Professional/invoice/Invoice.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalInvoces' }
          ]
        }
      },

      {
        path: 'invoicerecipt/:id',
        name: 'ProfessionalInvoceRecipt',
        component: () => import('../views/Professional/invoice/InvoiceRecipt.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalInvoceRecipt' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'ProfessionalTicket',
        component: () => import('../views/Professional/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalTicket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'ProfessionalTicketView',
        component: () => import('../views/Professional/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalTicketView' }
          ]
        }
      },
      {
        path: 'login', name: 'Login',
        component: () => import('../views/Professional/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/Professional/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'resetpassword', name: 'ResetPassword',
        component: () => import('../views/Professional/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },{
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/Professional/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      },
    ]