const PublicRouter = []
  PublicRouter.push(
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Public/Home.vue'),
        meta: {
          title: 'home title',
          redirectIfLoggedIn: 'false',
          headerVisible: 'show',
          breadCrumb: [
            { name: 'Home' }
          ]
        }
      },
    //   {
    //     path: 'login', name: 'Login',
    //     component: () => import('../views/staff/auth/Login.vue'),
    //     meta: { headerVisible: 'hide' }
    //   },

    )

 
  export default PublicRouter