<template>
<!-- 
  <footer class="footer footer-static footer-light">
    <p class="clearfix mb-0">
      <span class="float-md-start d-block d-md-inline-block mt-25"
        >COPYRIGHT &copy; 2021<a
          class="ms-25"
          href="https://www.vakilgiri.com/"
          target="_blank"
          style="color: #f21000"
          >Vakilgiri</a
        ><span class="d-none d-sm-inline-block">, All rights Reserved</span></span
      ><span class="float-md-end d-none d-md-block"
        >Hand-crafted & Made with<i data-feather="heart"></i
      ></span>
    </p> -->
  <!-- </footer> -->
<span></span>
</template>

<script>
export default {
  name: "Footer1",
};
</script>
